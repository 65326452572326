import React from 'react';

import styles from './HeroSectionDark.module.scss';
import { useScrollReveal } from '~/hooks/useScrollReveal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button } from '~/vinovest-design-system/components';
import { baseRoutes } from '~/routes/base';
import Image from 'next/image';
import Bottle1 from '~/assets/img/home/dark/hero/bottle-1.png';
import Bottle2 from '~/assets/img/home/dark/hero/bottle-2.png';
import Bottle3 from '~/assets/img/home/dark/hero/bottle-3.png';
import Bottle4 from '~/assets/img/home/dark/hero/bottle-4.png';
import { useWindowSize } from '~/hooks/useWindowSize';

export const HeroDark = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';
    const { isMobile } = useWindowSize();

    return (
        <>
            <div className={`${styles.HeroDesktopContainer}`} ref={ref}>
                <div className={`${styles.TitlesSection}`}>
                    <div className={`titlesWrapperContainer`}>
                        <div className={`titlesWrapper`}>
                            <h1 className={`${styles.HeroTitleHome} ${runAnimation}`}>
                                Timeless Investments. <br />
                                Modern Wealth.
                            </h1>
                            <p className={`${styles.HeroDescription} ${runAnimation}`}>
                                The world's leading wine and whiskey investment platform
                            </p>
                            <Button
                                variant={'green'}
                                href={baseRoutes.signup.href}
                                font={'font-vinovest-medium'}
                                classnames={`text-16 !capitalize ${styles.HeroButton} ${runAnimation}`}
                            >
                                Get Started
                            </Button>
                        </div>
                        <div
                            className={`w-full py-[0px] max-w-[875px] mx-auto flex flex-col md:flex-row flex-wrap md:mb-[70px] text-center mt-[500px] md:mt-0 ${styles.Stats} ${runAnimation}`}
                        >
                            <div className={`md:w-[33.33%] flex flex-col mb-[40px] md:mb-[20px]`}>
                                <span className={'text-gray-300 font-roslindale text-[36px]'}>200,000+</span>
                                <span className={'text-gray-400 text-[14px] font-vinovestmedium'}>Clients</span>
                            </div>
                            <div className={`md:w-[33.33%] flex flex-col mb-[40px] md:mb-[20px]`}>
                                <span className={'text-gray-300 font-roslindale text-[36px]'}>$140,000,000</span>
                                <span className={'text-gray-400 text-[14px] font-vinovestmedium'}>Invested</span>
                            </div>
                            <div className={`md:w-[33.33%] flex flex-col md:mb-[20px]`}>
                                <span className={'text-gray-300 font-roslindale text-[36px]'}>1,700,000+</span>
                                <span className={'text-gray-400 text-[14px] font-vinovestmedium'}>
                                    Bottles in our care
                                </span>
                            </div>
                        </div>
                        <div className={`${styles.bottle} ${styles.bottle1} ${runAnimation}`}>
                            <Image
                                src={Bottle1}
                                className={'bottleImage'}
                                width={isMobile ? '162' : '400'}
                                height={isMobile ? '240' : '650'}
                                alt={'Bottle 1'}
                                loading={'eager'}
                            />
                            <span>
                                +1,300%
                                <em>since release</em>
                            </span>
                        </div>
                        <div className={`${styles.bottle}  ${styles.bottle2} ${runAnimation}`}>
                            <Image
                                src={Bottle2}
                                className={'bottleImage'}
                                width={isMobile ? '260' : '800'}
                                height={isMobile ? '247' : '761'}
                                alt={'Bottle 2'}
                                loading={'eager'}
                            />
                            <span>
                                +34.17%
                                <em>since release</em>
                            </span>
                        </div>
                        <div className={`${styles.bottle} ${styles.bottle3} ${runAnimation}`}>
                            <Image
                                src={Bottle3}
                                className={'bottleImage'}
                                width={isMobile ? '76' : '227'}
                                height={isMobile ? '100' : '300'}
                                alt={'Bottle 3'}
                                loading={'eager'}
                            />
                            <span>
                                +62.1%
                                <em>since release</em>
                            </span>
                        </div>
                        <div className={`${styles.bottle} ${styles.bottle4} ${runAnimation}`}>
                            <Image
                                src={Bottle4}
                                className={'bottleImage'}
                                width={isMobile ? '159' : '294'}
                                height={isMobile ? '162' : '300'}
                                alt={'Bottle 4'}
                                loading={'eager'}
                            />
                            <span>
                                +168.4%
                                <em>since release</em>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
